export function initDisableRegistrationForPrivate() {
    document.querySelectorAll('.iconbarIcon--becomeReseller').forEach((registrationButton) => {
        registrationButton.addEventListener('click', () => {
            disableRegistrationForPrivate();
        })
    })
}

function disableRegistrationForPrivate() {
    document.querySelectorAll('#register_modal .contactformular form').forEach(
        registrationForm => {
            let radioBoxes = registrationForm.querySelectorAll('.specialradiobox');
            let checkboxPrivate = radioBoxes[1].querySelector('input');
            let registrationFormNotice = document.createElement("div");
            registrationFormNotice.classList.add('registrationNotice');
            registrationFormNotice.innerHTML = tc_registration_notice;
            registrationForm.append(registrationFormNotice);
            let registrationFormNoticeHeight = registrationFormNotice.getBoundingClientRect().height;

            registrationForm.addEventListener('change', evt => {
                if(!checkboxPrivate.checked) {
                    registrationForm.querySelectorAll('.inputGroup > input').forEach(
                        otherInput => {
                            otherInput.disabled = false;
                        }
                    )
                    registrationForm.querySelectorAll('#input_document').forEach(
                        otherInput => {
                            otherInput.disabled = false;
                        }
                    )
                    registrationForm.querySelector('#input_privacy_checkbox').disabled = false;
                    registrationForm.querySelector('.button').disabled = false;
                    registrationFormNotice.style.bottom = (-1 * registrationFormNoticeHeight) + 'px';
                } else {
                    registrationForm.querySelectorAll('.inputGroup > input').forEach(
                        otherInput => {
                            otherInput.disabled = true;
                        }
                    );
                    registrationForm.querySelectorAll('#input_document').forEach(
                        otherInput => {
                            otherInput.disabled = true;
                        }
                    )
                    registrationForm.querySelector('#input_privacy_checkbox').disabled = true;
                    registrationForm.querySelector('.button').disabled = true;
                    registrationFormNotice.style.bottom = '0';
                }
            });
        }
    )
}