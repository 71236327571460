export function manipulateScrollBar(query = document.querySelectorAll('.swiper-initialized.scrollbar')) {
    for (let slideshow of query) {
        var slideshowSwiper = slideshow.swiper;
        slideshowSwiper.params.slidesPerView = 6;
        slideshowSwiper.params.spaceBetween = 20;
        slideshowSwiper.params.pagination = false;

        if(window.innerWidth < 1250 && window.innerWidth > 860) {
            slideshowSwiper.params.slidesPerView = 3;
            slideshowSwiper.params.spaceBetween = 20;
        }

        if(window.innerWidth < 860 && window.innerWidth > 540) {
            slideshowSwiper.params.slidesPerView = 2;
            slideshowSwiper.params.spaceBetween = 20;
        }

        if(window.innerWidth < 540) {

            slideshowSwiper.params.slidesPerView = 2;
            slideshowSwiper.params.spaceBetween = 20;
        }

        /* slideshowSwiper.params.autoplay = false;

        var paginationEl = document.querySelector('.swiper-pagination');
        if (paginationEl) {
            paginationEl.remove();
        }

        // Entferne Pagination-Parameter aus dem Swiper-Objekt
        if (slideshowSwiper.pagination) {
            slideshowSwiper.pagination.destroy();
            slideshowSwiper.pagination = null;
        } */

        // Optional: Den Swiper neu initialisieren oder aktualisieren, falls nötig
        slideshowSwiper.update();
    }


}