import {FetchService} from "../../tritec-catalog/node_modules/dc-services/fetch.service";

export function initItemImagesDownload() {

    for (let productDownloadButton of document.querySelectorAll('.js-itemCardImageDownload')) {

        productDownloadButton.addEventListener('click', (e) => {

            e.preventDefault();
            productDownloadButton.classList.add('is-loading');
            const itemId = document.querySelector('input[name="item_id"]').value;
            const url = '/em/downloadItemImages/' + itemId + '/zip';
            getDownloadLink(url, productDownloadButton);

        });

    }

}


const getDownloadLink = async (url, downloadButton) => {
    const downloadLink = await FetchService.get(url);
    if (downloadLink.success) {
        downloadButton.classList.remove('is-loading');
        window.open(downloadLink.data);
    } else {
        downloadButton.classList.remove('is-loading');
        downloadButton.classList.add('is-error');
        setTimeout(() => {
            downloadButton.classList.remove('is-error');
        }, 1500);
    }
}