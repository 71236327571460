export function adjustedInitJumpmarks(offset = 0, query = document.querySelectorAll('a[href^="#"]')) {
    const header = document.querySelector('header.header');

    if(header) {
        const headerHeight = header.getBoundingClientRect().height;
        for (let jumpmark of query) {
            jumpmark.addEventListener("click", function (e) {
                e.preventDefault();
                if (jumpmark.closest(".modal")) {
                    return;
                }
                let href = jumpmark.getAttribute("href");
                if (href) {
                    if (href === "#") {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    } else {
                        let target = document.getElementById(href.replace("#", ""));
                        if (target) {
                            let top = window.scrollY + target.getBoundingClientRect().top;
                            window.scrollTo({
                                top: top - (headerHeight + 38),
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                    }
                }
            });
        }
    }
}