import {manipulateSlideshowItembox} from "../../../trienergy-global/js/manipulateSlideshowItembox";
import {manipulateSlideshowItemboxPreview} from "../../../trienergy-global/js/manipulateSlideshowItemboxPreview";
import {initItemImagesDownload} from "../../../trienergy-global/js/itemImagesDownload";
import {initMailchimp} from "../../../trienergy-global/js/initMailchimp";
import {resetNavTogglerPositionMobile} from "../../../trienergy-global/js/resetNavTogglerPositionMobile";
import {adjustedInitJumpmarks} from "../../../trienergy-global/js/adjustesInitJumpmarks";
import {catalogLoginHelper} from "./catalogLoginHelper";
import {manipulateScrollBar} from "../../../trienergy-global/js/manipulateScrollBar";
import {headerStickyClass} from "../../../trienergy-global/js/stickyHeader";
import {initDisableRegistrationForPrivate} from "./disableRegistrationForPrivate";

window.addEventListener('load', function() {
    manipulateSlideshowItembox();
    manipulateSlideshowItemboxPreview();
    initItemImagesDownload();
    initMailchimp();
    resetNavTogglerPositionMobile();
    adjustedInitJumpmarks();
    catalogLoginHelper();
    manipulateScrollBar();
    headerStickyClass();
    initDisableRegistrationForPrivate();
});

window.addEventListener('resize', function() {
    manipulateSlideshowItembox();
    manipulateSlideshowItemboxPreview();
    resetNavTogglerPositionMobile();
    headerStickyClass();
});

window.addEventListener('scroll', function() {
    headerStickyClass();
});