export function headerStickyClass() {
    document.querySelectorAll('header.header').forEach(header => {
        const windowScroll = window.scrollY;
        const headerTrust = document.querySelector('.headerTrust');
        if (headerTrust) {
            const headerTrustHeight = headerTrust.getBoundingClientRect().height;
            if (headerTrustHeight < windowScroll) {
                header.classList.add('is-sticky');
            } else {
                header.classList.remove('is-sticky');
            }
        } else {
            if (windowScroll > 0) {
                header.classList.add('is-sticky');
            } else {
                header.classList.remove('is-sticky');
            }
        }
    })
}